import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentModalService } from '../../services/comment-modal-service/comment-modal.service';
// import { SignupModalService } from 'src/app/services/signup-modal/signup-modal.service';
import {saveJwtToken, logout, isJwtTokenExpired, getJwtToken, getJwtTokenData } from "../../utils/auth";
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SharedModule } from '../../shared.module';
import { addLoginUserToState } from '../../states/user/user.actions';
import { selectUser } from '../../states/user/user.selector';
import { AppState } from '../../states/app.state';
import { timeout } from 'rxjs/operators';
import { EventService } from '../../services/event-service/event.service';

@Component({
  selector: 'app-comment-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './comment-modal.component.html',
  styleUrl: './comment-modal.component.css'
})
export class CommentModalComponent implements OnInit{

  @Input() event_id: string = ""; 
  myCommentForm: FormGroup;
  loading = false; // Add loading flag
  isUserLoggedIn:boolean = false;
  loggedInUserData: any; 
  setEditComment: boolean = false;

  isUpdateComment: boolean = false;

  comments: any[] = [];
  commentForUpdate: any;
  eventTimes: any[] = [];

  constructor(
    private commentModalService: CommentModalService,
    // private eventService: EventService,
    // private signupModalService: SignupModalService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private toastr: ToastrService
    ) {
      this.myCommentForm = this.formBuilder.group({
        comment: ['', Validators.required],
        rating: ['', Validators.required],
      });
    }

    
  ngOnInit(): void {
    if(isJwtTokenExpired()){ //If user is not logged in(if true)
      this.isUserLoggedIn = false;
    }else{ //if user is logged in
      this.isUserLoggedIn = true;

       // Fetch user data from storage
     const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

     if (storedUserData) {
       this.store.dispatch(addLoginUserToState({ user: storedUserData }));
     }

     this.store.select(selectUser).subscribe(userState => {
      this.loggedInUserData = userState;
      console.log("loggedInUserData from event list ola", this.loggedInUserData); 
      // console.log("loggedInUserId", this.loggedInUserData.user_id); 
     });

    //  alert(this.event_id);

      this.getCommentsOnModalLoad(this.event_id);
    }

  }

  getCommentsOnModalLoad(event_id: string){
    this.commentModalService.getCommentsForAEvent(event_id).subscribe({
      next: (response) => {
        // Handle successful response here
        console.log('Get successful Comments:', response);
        this.comments = response.eventComments;

      },
      error: (error) => {
        console.error('Error fetching Comments:', error);
      },
      complete: () => {}
    });
}

formatDateTime(dateTimeString: any) {
  // Parse the date and time string
  const dateTime = new Date(dateTimeString);

  // Format the date and time
  const formattedDateTime = `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
  
  return formattedDateTime;
}


  closeModal() {
    this.commentModalService.closeModal();
  }


    onRatingChange(selectedValue: any) {
      
    }

    deleteComment(comment: any){
      // alert(comment.comment_id);
      // this.getCommentsOnModalLoad(comment_id);

      this.commentModalService.deleteComment(comment.comment_id)
      .pipe(
        timeout(40000) // Timeout set to 40 seconds (40000 milliseconds)
      )
      .subscribe({
        next: (response) => {
          // Handle successful response here
          console.log('Comment deleted successful:', response);
          if(response.status == false){
            this.toastr.error(`${response.message}`, '', {
              closeButton: true,
            });

          }else{
          this.toastr.success(`${response.message}`, '', {
            closeButton: true,
          });
          this.getCommentsOnModalLoad(comment?.event_id);
          }
        
    
        },
        error: (error) => {
          console.error('Error deleting Comment:', error);
          this.loading = false; 
          this.toastr.error('Unable to delete comment', '', {
            closeButton: true,
          });
        },
        complete: () => {
          this.loading = false; 
        }
      });
    }

    editComment(comment: any){
      this.isUpdateComment = true;
      this.commentForUpdate = comment;

      this.myCommentForm.setValue({
        comment: comment.comment_text,
        rating: comment.rating,
      });

      // alert("I want to edit comment " + comment_id);
    }

    resetTheForm(){
      this.myCommentForm.setValue({
        comment: "",
        rating: "",
      });
    }


  onSubmit() {

     // Access form values here
     const comment = this.myCommentForm.get('comment')?.value;
     const rating = this.myCommentForm.get('rating')?.value;
     // alert(rating + ' ' + comment);

    if(rating === ""){
      this.toastr.error('Rating is required', '', { closeButton: true,  });
      return
    }

    if(comment === ""){
      this.toastr.error('Comment is required', '', { closeButton: true,  });
      return
    }

    if (this.myCommentForm.invalid) {
      // Form is valid, perform signup logic
      console.log("error validation");
      return
    } 

    // Set loading to true
    this.loading = true;

   

    if(this.isUpdateComment == true){ //user want to update comment
      // console.log(this.commentForUpdate);
      // console.log(this.commentForUpdate?.event_id);
      let commentData = {
        comment_id: this.commentForUpdate.comment_id,
        user_id: this.commentForUpdate.user_id,
        event_id: this.commentForUpdate.event_id,
        comment_text: comment,
        rating: rating,
      }

      this.commentModalService.updateComment(commentData)
      .pipe(
        timeout(40000) // Timeout set to 40 seconds (40000 milliseconds)
       )
       .subscribe({
        next: (response) => {
          // Handle successful response here
          console.log('Comment Updated successful:', response);
          this.toastr.success(`Comment Updated successful`, '', {
            closeButton: true,
          });

          this.getCommentsOnModalLoad(this.commentForUpdate?.event_id);
          // this.resetTheForm();
    
        },
        error: (error) => {
          console.error('Error Updating comment:', error);
          this.loading = false; 
          this.toastr.error('Error Updating comment', '', {
            closeButton: true,
          });
        },
        complete: () => {
          this.loading = false; 
        }
      });
      

    }else{ //user wwant to create comment

      let createCommentData = {
        user_id: this.loggedInUserData.user_id,
        event_id: this.event_id,
        comment_text: comment,
        rating: rating,
      }
      console.log(createCommentData);

        this.commentModalService.createComment(createCommentData)
        .pipe(
          timeout(40000) // Timeout set to 40 seconds (40000 milliseconds)
        )
        .subscribe({
          next: (response) => {
            // Handle successful response here
            console.log('Comment created successful:', response);
            if(response.status == false){
              this.toastr.error(`${response.message}`, '', {
                closeButton: true,
              });

            }else{
            this.toastr.success(`${response.message}`, '', {
              closeButton: true,
            });
            this.getCommentsOnModalLoad(createCommentData?.event_id);
            this.resetTheForm();
            }
          
      
          },
          error: (error) => {
            console.error('Error creating Comment:', error);
            this.loading = false; 
            this.toastr.error('Unable to create comment', '', {
              closeButton: true,
            });
          },
          complete: () => {
            this.loading = false; 
          }
        });

    }
  
  }


}
