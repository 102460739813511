<div *ngIf="setToLoadingWhileGetData"> 
  <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i> Loading Data...
</div>

<div *ngIf="!setToLoadingWhileGetData">
  <section *ngFor="let event of event" class="eventbody text-gray-600 body-font">
    <div class="container mx-auto flex mt-2 items-center justify-center flex-col">
      <div class="text-center lg:w-2/3 w-full">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{{ event.event_name }}</h1>
        <p class="mb-8 leading-relaxed">{{ event.description }}</p>
        <div class="flex justify-center">
          <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
          <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
        </div>
      </div>

        <div class="contact-body text-gray-600 mt-2 body-font">
            <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div class="flex-shrink-0 w-20 h-20 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Contact Information</h2>
                <p class="leading-relaxed">{{ event.location_name }}</p>
                <p class="leading-relaxed">{{ event.contact_email }}</p>
                <p class="leading-relaxed">{{ event.contact_phone }}</p>
              </div>
            </div>
        </div> 

        <!-- google map -->
        <div class="google-map-body">
            <google-map
                height="380px"
                width="100%"
                [center]="center"
                [zoom]="zoom"
                (mapClick)="moveMap($event)"
                (mapMousemove)="move($event)"
            >
                <!-- <map-marker [position]="center" [icon]="icon" [label]="location_name"></map-marker> -->
            </google-map>
        </div>
    </div>
  </section>
</div>


