import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { EventService } from '../../services/event-service/event.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../states/app.state';
import { ToastrService } from 'ngx-toastr';
import { isJwtTokenExpired } from '../../utils/auth';
import { addLoginUserToState } from '../../states/user/user.actions';
import { selectUser } from '../../states/user/user.selector';
import { CommentModalService } from '../../services/comment-modal-service/comment-modal.service';

@Component({
  selector: 'app-event-list-page',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './event-list-page.component.html',
  styleUrl: './event-list-page.component.css'
})
export class EventListPageComponent implements OnInit{

  events: any[] = [];
  setToLoadingWhileGetData = false;
  isUserLoggedIn:boolean = false;
  loggedInUserData: any; 

  constructor(
    private eventService: EventService,
    private commentModalService: CommentModalService,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService
  ){}

  goToDetail(id: string) {
    this.router.navigate(['/event', id]);
  }


  userComments(event_id: string){
    if(this.isUserLoggedIn == true){
      this.commentModalService.openModal(event_id);
    }else{
      this.router.navigate(['/login']);
    }
   
  }

  ngOnInit(): void {
    if(isJwtTokenExpired()){ //If user is not logged in(if true)
      this.isUserLoggedIn = false;
    }else{ //if user is logged in
      this.isUserLoggedIn = true;

       // Fetch user data from storage
     const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

     if (storedUserData) {
       this.store.dispatch(addLoginUserToState({ user: storedUserData }));
     }

     this.store.select(selectUser).subscribe(userState => {
      this.loggedInUserData = userState;
      console.log("loggedInUserData from event list ola", this.loggedInUserData); 
      // console.log("loggedInUserId", this.loggedInUserData.user_id); 
     });
    }
    this.getAllEventOnPageLoad();
  }


  // goToDetail(id: string) {
  //   const url = `/item/${id}`;
  //   this.router.navigateByUrl(url);
  // }

  formatDate(dateString: string): string {
    const eventDate = new Date(dateString);
    return eventDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }



  getAllEventOnPageLoad(){
    this.setToLoadingWhileGetData = true;
    this.eventService.getAllEvents().subscribe({
      next: (response) => {
        // Handle successful response here
        console.log('Get successful events:', response);
        this.events = response.events;

      },
      error: (error) => {
        console.error('Error fetching events:', error);

        this.toastr.error('Error fetching events', '', {
          closeButton: true,
        });
      },
      complete: () => {
        this.setToLoadingWhileGetData = false;
      }
    });
}


}
