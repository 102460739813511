<!-- header -->
<app-header></app-header>

<!-- router body -->
<router-outlet></router-outlet>

<!-- footer -->
<app-footer></app-footer>


<!-- type pausing -->
<!-- Update notification -->
<!-- <div *ngIf="isThereNotification" class="notification-container animate__animated animate__fadeInUp">
    <ngx-typed-js [strings]="[notificationMessage]">
        <span class="typing"></span>
    </ngx-typed-js>
    <button class="notify-button" (click)="reloadPage()">Reload</button>
</div> -->
