<section class="dashboard-body">
    <div class="ag-format-container">
        <div class="ag-courses_box">
          <div class="ag-courses_item">
            <a routerLink="/profile" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
               Profile
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Manage your profile
                </span>
              </div>
            </a>
          </div>
      
          <div class="ag-courses_item">
            <a routerLink="/create-an-event" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                Events
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Create An Event
                </span>
              </div>
            </a>
          </div>
      
          <div class="ag-courses_item">
            <a routerLink="/rso" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                RSOs
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Create A RSOs
                </span>
              </div>
            </a>
          </div>
      
          <div class="ag-courses_item">
            <a routerLink="/university" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                University
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Create A University
                </span>
              </div>
            </a>
          </div>
      
          
         <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                List Events
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                 View & Manage your Events
                </span>
              </div>
            </a>
         </div>


         <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                List University
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                 View & Manage your University
                </span>
              </div>
            </a>
         </div>

         <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                List RSOs
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                 View & Manage your RSOs
                </span>
              </div>
            </a>
         </div>

         
         <div class="ag-courses_item">
            <a routerLink="/test" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                Test
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Check Test
                </span>
              </div>
            </a>
          </div>

         
<!-- 
         <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                Interior Design
              </div>
      
              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">
                  31.10.2022
                </span>
              </div>
            </a>
         </div> -->

         <!-- <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                Interior Design
              </div>
      
              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">
                  31.10.2022
                </span>
              </div>
            </a>
         </div> -->


         <div (click)="logOutAUser()" class="ag-courses_item">
            <a href="javascript:void(0);" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
      
              <div class="ag-courses-item_title">
                Sign Out
              </div>
      
              <div class="ag-courses-item_date-box">
                <!-- Start: -->
                <span class="ag-courses-item_date">
                  Log out of your account
                </span>
              </div>
            </a>
          </div>
       
        </div>
      </div>
      
      

</section>

