<section class="body-content text-gray-600 body-font">

  <!-- <button (click)="goToDetail('1')">My button</button> -->

  <!-- Search bar -->
    <form class="searchbar-container max-w-md mx-auto">   
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
          </div>
          <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." required />
          <button type="button" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
      </div>
    </form>
    <!-- end of search bar -->

    <div class="container px-5 mx-auto">
      <div class="-my-8 divide-y-2 divide-gray-100">

        <div *ngIf="setToLoadingWhileGetData"> 
          <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i> Loading Data...
        </div>
        <div *ngIf="!setToLoadingWhileGetData">
          <div *ngFor="let event of events" class="py-8 flex flex-wrap md:flex-nowrap">
            <ng-container *ngIf="event.visibility_id === 1"> 
              <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span class="font-semibold title-font text-gray-700"><i class="fa fa-tag" aria-hidden="true"></i> {{ event.category_name }}</span>
                <span class="mt-1 text-gray-500 text-sm">{{ formatDate(event.event_date) }}</span>
              </div>
              
              <div class="md:flex-grow">
                <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">{{ event.event_name }}</h2>
                <p class="leading-relaxed">{{ event.about }}</p>
                <a class="text-green-500 inline-flex items-center mt-4 cursor-pointer mr-3" (click)="userComments(event.event_id)">
                  Comments
                </a>
                <a class="text-yellow-500 inline-flex items-center mt-4 cursor-pointer" (click)="goToDetail(event.event_id)">
                  Learn More
                  <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </ng-container>
            
            <ng-container *ngIf="event.visibility_id === 2 && isUserLoggedIn && event.uni_id === loggedInUserData.uni_Id">
              <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span class="font-semibold title-font text-gray-700"><i class="fa fa-tag" aria-hidden="true"></i> {{ event.category_name }}</span>
                <span class="mt-1 text-gray-500 text-sm">{{ formatDate(event.event_date) }}</span>
              </div>
              
              <div class="md:flex-grow">
                <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">{{ event.event_name }}</h2>
                <p class="leading-relaxed">{{ event.about }}</p>
                <a class="text-green-500 inline-flex items-center mt-4 cursor-pointer mr-3" (click)="userComments(event.event_id)">
                  Comments
                </a>
                <a class="text-yellow-500 inline-flex items-center mt-4 cursor-pointer" (click)="goToDetail(event.event_id)">
                  Learn More
                  <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </ng-container>
            
            <!-- <ng-container *ngIf="condition3">
             
            </ng-container> -->

          </div>
         
        </div>

      </div>
    </div>
</section>

