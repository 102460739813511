<!-- <p>profile-page works!</p>


<div *ngIf="user$ | async as user">
    <h2>Welcome, {{ user.firstName }} {{ user.lastName }}!</h2>
    <p>Email: {{ user.email }}</p>
    <p>User Level: {{ user.user_level }}</p>
    <p>created at: {{ user.created_at }}</p>
  </div> -->


  <div class="profile-section container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Profile</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
                <div class="mt-2">
                  <input formControlName="firstName" type="text" name="first-name" id="first-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
      
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last name</label>
                <div class="mt-2">
                  <input formControlName="lastName" type="text" name="last-name" id="last-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="col-span-full">
                <label for="about" class="block text-sm font-medium leading-6 text-gray-900">About</label>
                <div class="mt-2">
                  <textarea formControlName="about" id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <p class="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
              </div>

              <div class="col-span-full">
                <label for="photo" class="block text-sm font-medium leading-6 text-gray-900">University Logo</label>
                <div class="mt-2 flex items-center gap-x-3">
                  <img class="h-12 w-12 text-gray-300" [src]="selectedImageUrl" />
                   <label for="photo-upload" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                     <span class="cursor-pointer">Upload a Photo</span>
                     <input (change)="onPhotoFileSelected($event)" id="photo-upload" name="photo-upload" type="file" class="sr-only">
                   </label>
                </div>
              </div>
      
      
              <div class="col-span-full">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div class="mt-2">
                  <input formControlName="email" id="email" name="email" type="email" autocomplete="email" class="cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="col-span-full">
                <label for="user-role" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
                <div class="mt-2">
                  <input formControlName="userRole" id="user-role" name="user-role" type="text" class="cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="col-span-full">
                <label for="university" class="block text-sm font-medium leading-6 text-gray-900">University</label>
                <div class="mt-2">
                  <input formControlName="university" id="university" name="university" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

            </div>
          </div>
      
        </div>
      
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <!-- <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> -->
          <button type="submit" [disabled]="loading" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <span *ngIf="!loading">Update Profile</span>
            <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin" style="font-size: 24px;"></i></span>
          </button>
        </div>

    </form>
  </div>