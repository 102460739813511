import { Component } from '@angular/core';

@Component({
  selector: 'app-add-member-to-rso',
  standalone: true,
  imports: [],
  templateUrl: './add-member-to-rso.component.html',
  styleUrl: './add-member-to-rso.component.css'
})
export class AddMemberToRsoComponent {

}
