import { Component } from '@angular/core';

@Component({
  selector: 'app-forget-password-page',
  standalone: true,
  imports: [],
  templateUrl: './forget-password-page.component.html',
  styleUrl: './forget-password-page.component.css'
})
export class ForgetPasswordPageComponent {

}
