import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommentModalComponent } from '../../components/comment-modal/comment-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommentModalService {

  private modalComponentRef: any;
  private baseUrl = environment.apiUrl; 

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private http: HttpClient
  ) { }


  getCommentsForAEvent(eventId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/event/comments/${eventId}`);
  }

  deleteComment(comment_id: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/event/comment/delete/${comment_id}`);
  }

  createComment(commentData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/event/comment/create`, commentData);
  }

  updateComment(commentData: any): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl}/event/comment/update/${commentData.comment_id}`, commentData);
  }

  openModal(event_id: string) {
    // Create component reference from the component
    const factory = this.componentFactoryResolver.resolveComponentFactory(CommentModalComponent);
    this.modalComponentRef = factory.create(this.injector);

    // Set input properties of the modal component
    this.modalComponentRef.instance.event_id = event_id;

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.modalComponentRef.hostView);

    // Get DOM element from component
    const domElem = (this.modalComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
  }


  closeModal() {
    if (this.modalComponentRef) {
      this.appRef.detachView(this.modalComponentRef.hostView);
      this.modalComponentRef.destroy();
    }
  }
}
