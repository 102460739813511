import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl = environment.apiUrl; 

 constructor(private http: HttpClient) { }

  getProfileById(profileId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/profile/${profileId}`);
  }


  createRsoGroup(rsoData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/rso/create`, rsoData);
  }

  getAllUserInASchool(uni_Id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/user/getallbyschool/${uni_Id}`);
  }
}
