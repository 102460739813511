import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniversityService {

  private baseUrl = environment.apiUrl; 

  private googleMapApiKey = environment.googleMapApiKey; 

 constructor(private http: HttpClient) { }

 getGeocodingForAdddress(address: string): Observable<any> {
   return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.googleMapApiKey}`);
 }


  createUniversity(universityData: any): Observable<any> {
   return this.http.post<any>(`${this.baseUrl}/university/create`, universityData);
 }

//  getAllEventCategory(): Observable<any> {
//    return this.http.get<{name: string}[]>(`${this.baseUrl}/event/category`);
//  }





}
