import { Component, ElementRef  } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../../states/app.state';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { ProfileService } from '../../services/profile-service/profile.service';
import { EventService } from '../../services/event-service/event.service';
import { ToastrService } from 'ngx-toastr';
import { isJwtTokenExpired } from '../../utils/auth';
import { addLoginUserToState } from '../../states/user/user.actions';

import { AddMemberToRsoComponent } from '../../components/add-member-to-rso/add-member-to-rso.component';
import { selectUser } from '../../states/user/user.selector';
import { timeout } from 'rxjs/operators';


@Component({
  selector: 'app-rso-page',
  standalone: true,
  imports: [SharedModule, AddMemberToRsoComponent],
  templateUrl: './rso-page.component.html',
  styleUrl: './rso-page.component.css'
})
export class RsoPageComponent {

  user$ = this.store.select(selectUser);
  userId: string = "";
  userDbDetails: any = [];
  loggedInUserData: any; 
  loading = false;
  rsoForm: FormGroup; 

  students: any[] = [];

  selectedImageUrl: string | ArrayBuffer | null = 'https://cdn-icons-png.flaticon.com/512/3237/3237472.png';
  selectedPhotoFile: File | null = null;

  selectedCoverPhotoFile: File | null = null;
  selectedCoverImageUrl: string | ArrayBuffer | null = 'https://cdn-icons-png.flaticon.com/512/5175/5175601.png'; // Initial image URL

  

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private eventService: EventService,
    private elementRef:ElementRef,
    private toastr: ToastrService
 ){ 
    this.rsoForm = this.formBuilder.group({
      rsoName: ['', [Validators.required]],
      // addMember: ['', [Validators.required]],
      about: ['', [Validators.required]],
      ContactEmail: ['', [Validators.required, Validators.email]],
      ContactPhone: ['', [Validators.required, this.validateUniversityNumber]],
    });
    this.dropdowns.forEach((_, index) => {
      this.rsoForm.addControl(`addMember${index}`, this.formBuilder.control(''));
    });
 }


 ngOnInit(): void {
  /******************Logged user code************************* */
  if(isJwtTokenExpired()){ //if true
    this.router.navigate(['/login']);
    return
  }
  

   // Fetch user data from storage
   const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

   if (storedUserData) {
     this.store.dispatch(addLoginUserToState({ user: storedUserData }));
   }
  /******************end of user code************************* */   

  this.store.select(selectUser).subscribe(userState => {
    this.loggedInUserData = userState;
   });
  


  this.getUserProfileOnPageLoad(this.loggedInUserData.user_id);
 
}

dropdowns: any[] = [{}]; // Initial dropdown
selectedValues: string[] = []; // Array to store selected values

async getUserProfileOnPageLoad(userId: string){
  this.profileService.getProfileById(userId).subscribe({
    next: (response) => {
      // Handle successful response here
      console.log('Get successful user details on RSO Page:', response);
      this.userDbDetails = response.user;
    },
    error: (error) => {
      console.error('Error fetching user details on RSO Page:', error);
    },
    complete: () => {
      this.getAllUserforRsoMemberOnPageLoad();
    }
  });
}

getAllUserforRsoMemberOnPageLoad(){
  this.profileService.getAllUserInASchool(this.userDbDetails[0].uni_id).subscribe({
    next: (response) => {
      // Handle successful response here
      console.log('Get successful:', response);
      this.students = response.user;

    },
    error: (error) => {
      console.error('Error fetching students:', error);
    },
    complete: () => {
      //this.loading = false; // Set loading back to false after the request (regardless of success or error)
    }
  });
}

onPhotoFileSelected(event: any) {
  this.selectedPhotoFile = event.target.files[0] as File;

  const file: File = event.target.files[0];
  const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedImageUrl = e.target?.result as string;
    };
    reader.readAsDataURL(file);
}

onCoverPhotoFileSelected(event: any) {
  this.selectedCoverPhotoFile = event.target.files[0] as File;

  const file: File = event.target.files[0];
  const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedCoverImageUrl = e.target?.result as string;
    };
    reader.readAsDataURL(file);
}



// Method to handle dropdown change
onDropdownChange(event: any, index: number) {
  const value = event.target.value;
  this.selectedValues[index] = value;
  console.log('Selected values:', this.selectedValues);
}

validateUniversityNumber(control:any) {
  const numberPattern = /^[0-9]+$/;
  if (control.value && !numberPattern.test(control.value)) {
    return { error: "Only number Allowed" };
  }
  return null;
}


  addDropdown() {
    // this.dropdowns.push({});
    // this.selectedValues[0] = "";
    this.dropdowns.push({});
  const newIndex = this.dropdowns.length - 1;
  this.rsoForm.addControl(`addMember${newIndex}`, this.formBuilder.control(''));
  }

  deleteDropdown(index: number) {
    this.dropdowns.splice(index, 1);
    this.selectedValues.splice(index, 1); // Remove selected value when deleting dropdown
  }

  updateSelectedValue(event: Event, index: number) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.selectedValues[index] = value;
  }

  logSelectedValues() {
    console.log(this.selectedValues);
  }

  hasDuplicates(array: any[]): boolean {
    for (let i = 0; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (array[i] === array[j]) {
          // If a duplicate is found, return true
          return true;
        }
      }
    }
    // If no duplicates are found, return false
    return false;
  }

  resetTheForm(){
    // this.rsoForm.reset();
  }


  onSubmit(){

    const rsoName = this.rsoForm.get('rsoName')?.value;
    const rsoAbout = this.rsoForm.get('about')?.value;
    const ContactEmail = this.rsoForm.get('ContactEmail')?.value;
    const ContactPhone = this.rsoForm.get('ContactPhone')?.value;

    if(rsoName === ""){
      this.toastr.error('Rso Name is required', '', { closeButton: true, });
      return
    }
    
    if(rsoAbout === ""){
      this.toastr.error('About is required', '', { closeButton: true, });
      return
    }

    if(ContactEmail === ""){
      this.toastr.error('Email is required', '', { closeButton: true, });
      return
    }

    if(ContactPhone === ""){
      this.toastr.error('Phone is required', '', { closeButton: true, });
      return
    }
 

    if (!this.selectedPhotoFile) {
      this.toastr.error('Upload a Photo', '', {
        closeButton: true,
      });
      return;
    }
  
    if (!this.selectedCoverPhotoFile) {
      this.toastr.error('Upload a cover Image', '', {
        closeButton: true,
      });
      return;
    }

    if(this.hasDuplicates(this.selectedValues) == true){
      this.toastr.error('You cannot have same student more than once', '', {
        closeButton: true,
      });
      return;
    }

    if(this.selectedValues.includes(this.loggedInUserData.user_id.toString())){
      this.toastr.error('You are creating the RSO. You cannot add your self as a member', '', {
        closeButton: true,
      });
      return;
    }
    
    if(this.selectedValues.length < 4){
      this.toastr.error('You need to select at least 4 student to be a member', '', {
        closeButton: true,
      });
      return;
    }

    this.loading = true; 

   

    const rsoData:any = {rsoName, rsoAbout, ContactEmail, ContactPhone}


    const formData = new FormData();

    // Append file to FormData
    formData.append('file', this.selectedCoverPhotoFile);
    formData.append('file2', this.selectedPhotoFile);

    
    // Append other data to FormData
    Object.keys(rsoData).forEach((key) => {
      formData.append(key, rsoData[key]);
    });
  

    this.profileService.createRsoGroup(formData)
    .pipe(
     timeout(40000) // Timeout set to 40 seconds (40000 milliseconds)
    )
    .subscribe({
      next: (response) => {
        // Handle successful response here
        console.log('Post event Data successful:', response);
        this.toastr.success(`${response.message}`, '', {
          closeButton: true,
        });
        this.resetTheForm();
  
      },
      error: (error) => {
        console.error('Error creating RSO group:', error);
        this.loading = false; 
        if(error.status == 0){
          this.toastr.error('Request Timeout. The Request is taking too long, try again later', '', {
            closeButton: true,
          });
        }else{
          this.toastr.error('Unable to create event', '', {
            closeButton: true,
          });
        }
    
      },
      complete: () => {
        this.loading = false; 
      }
    });
   
  }




}
