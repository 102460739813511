import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChooseYourSchoolModalService } from '../../services/choose-your-school-modal/choose-your-school-modal.service';
// import { SignupModalService } from 'src/app/services/signup-modal/signup-modal.service';
import {saveJwtToken, logout, isJwtTokenExpired, getJwtToken, getJwtTokenData } from "../../utils/auth";
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-choose-your-school-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './choose-your-school-modal.component.html',
  styleUrl: './choose-your-school-modal.component.css'
})
export class ChooseYourSchoolModalComponent {

  myLoginForm: FormGroup;
  isPasswordVisible: boolean = false;
  loading = false; // Add loading flag
  loginSuccess = false; // Add loading flag

  constructor(
    private store: Store, 
    private chooseYourSchoolModalService: ChooseYourSchoolModalService,
    // private signupModalService: SignupModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
    ) {
      this.myLoginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
    }

 getTokens  () {
    console.log(getJwtToken());
    this.toastr.success('Hello world!', 'Toastr fun!');
 }

 logOut  () {
    console.log(logout());
 }

  checkExpired  () {
    console.log(isJwtTokenExpired());
  }

  checkTokenData  () {
    console.log(getJwtTokenData());
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }


  closeModal() {
    this.chooseYourSchoolModalService.closeModal();
  }

  openSignupModal(){
    this.chooseYourSchoolModalService.closeModal();
    // this.signupModalService.openModal();
  }


  onSubmit() {

    if (this.myLoginForm.invalid) {
      // Form is valid, perform signup logic
      console.log("error validation");
      return
    } 

    // Set loading to true
    this.loading = true;

    // Access form values here
    const email = this.myLoginForm.get('email')?.value;
    const password = this.myLoginForm.get('password')?.value;


    // Call a function to send data to the backend
    this.sendDataToBackend(email, password)
    .subscribe(
      (response:any) => {
        console.log(response);
        if(response.status){  //if true
          this.toastr.success(response.message);
          saveJwtToken(response.token.accessToken);
          this.loginSuccess = true;
          const user: any = {
            firstName: "Tunde",
            lastName: "Seriki",
            email: "tunde@gmail.com"
          }
          // this.store.dispatch(setUser(user));
          this.closeModal();
        }else{
          this.toastr.error(response.message);
        }
      },
      (error:any) => {
        console.error('Error logging In:', error);
        // Handle errors here
      }
    ).add(() => {
      // Set loading back to false when the request is completed (success or error)
      this.loading = false;
      
    });

  
  }

  sendDataToBackend(email: string, password: string) {
    return this.chooseYourSchoolModalService.logIn(email, password);
  }

}
