import { Component, Inject, OnInit, Renderer2, ElementRef  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../../states/app.state';
import { addLoginUserToState } from '../../states/user/user.actions';
import { selectUser } from '../../states/user/user.selector';
import { isJwtTokenExpired } from '../../utils/auth';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { EventService } from '../../services/event-service/event.service';
import { ToastrService } from 'ngx-toastr';
import { UserState } from '../../states/user/user.reducer';



@Component({
  selector: 'app-create-event-page',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './create-event-page.component.html',
  styleUrl: './create-event-page.component.css'
})
export class CreateEventPageComponent implements OnInit{

  createEventForm: FormGroup;
  loading = false; // Add loading flag
  createEventSuccess = false; // Add loading flag
  categories: { category_id: number, name: string }[] = [];

  visibilities: any[] = [];
  eventTimes: any[] = [];

  eventMaplatitude: any = "inital value lat";
  eventMaplongitude: any = "initial value lng";
  eventMapGeocode: any
  loggedInUserData: any; 

  showRsoOptionSelect : boolean = false;

  user$ = this.store.select(selectUser);
  
  constructor(
     private router: Router,
     private store: Store<AppState>,
     private formBuilder: FormBuilder,
     private eventService: EventService,
     private elementRef:ElementRef,
     private toastr: ToastrService
  ){ 
    this.createEventForm = this.formBuilder.group({
      eventName: ['', [Validators.required]],
      eventAbout: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      eventLocationName: ['', [Validators.required]],
      // eventAddress: ['', [Validators.required]],
      // eventCity: ['', [Validators.required]],
      // eventState: ['', [Validators.required]],
      // eventZipCode: ['', [Validators.required]],
      eventCategory: ['', [Validators.required]],
      eventVisibility: ['', [Validators.required]],
      eventStartTime: ['', [Validators.required]],
      eventEndTime: ['', [Validators.required]],
      eventEmail: ['', [Validators.required]],
      eventContactName: ['', [Validators.required]],
      eventContactPhone: ['', [Validators.required]],
      additionalInfo: [''],
    }, { validators: this.validateTimes });
  }

  ngOnInit(): void {
    /******************Logged user code************************* */

    if(isJwtTokenExpired()){ //if true
      this.router.navigate(['/login']);
      return
    }

     // Fetch user data from storage
     const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

     if (storedUserData) {
       this.store.dispatch(addLoginUserToState({ user: storedUserData }));
     }

     this.store.select(selectUser).subscribe(userState => {
      this.loggedInUserData = userState;
      console.log("loggedInUserData", this.loggedInUserData); 
     });

     /******************End Logged user code************************* */

     this.getAllEventCategoryOnPageLoad();
     this.getAllVisibilityOnPageLoad();
     this.getAllEventTimeOnPageLoad();

     this.initAutocomplete();
     this.loadScript();

    
     
  }

  loadScript() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/datepicker.min.js";
    this.elementRef.nativeElement.appendChild(s);
  }

  addressControl = new FormControl();
  cityControl = new FormControl();
  stateControl = new FormControl();
  zipControl = new FormControl();


  autocomplete: any;

  addressForm = new FormGroup({
    eventAddress: this.addressControl,
    eventCity: this.cityControl,
    eventState: this.stateControl,
    eventZipCode: this.zipControl
  });



  initAutocomplete() {
    const autocompleteInput = document.getElementById('autocompleteInput') as HTMLInputElement;
    this.autocomplete = new google.maps.places.Autocomplete(autocompleteInput);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      // this.addressControl.setValue(place.formatted_address);

      // Extract city, state/province, and ZIP/postal code from address components
      const addressComponents = place.address_components;

      // Extract street address
      const streetAddress = place?.formatted_address.split(',')[0];
      // Set street address to form control
      this.addressControl.setValue(streetAddress);

      addressComponents.forEach((component: { types: string | string[]; long_name: any; }) => {
        if (component.types.includes('locality')) {
          this.cityControl.setValue(component.long_name);
        } else if (component.types.includes('administrative_area_level_1')) {
          this.stateControl.setValue(component.long_name);
        } else if (component.types.includes('postal_code')) {
          this.zipControl.setValue(component.long_name);
        }
      });
    });
  }

onVisibilityChange(selectedValue: any) {
    if(selectedValue == 3){
      this.showRsoOptionSelect = true;
    }else{
      this.showRsoOptionSelect = false;
    }
}


  getAllEventCategoryOnPageLoad(){
      this.eventService.getAllEventCategory().subscribe({
        next: (response) => {
          // Handle successful response here
          console.log('Get successful:', response);
          this.categories = response.eventCategory;

        },
        error: (error) => {
          console.error('Error fetching categories:', error);
        },
        complete: () => {}
      });
  }

  getAllVisibilityOnPageLoad(){
    this.eventService.getAllVisibility().subscribe({
      next: (response) => {
        // Handle successful response here
        console.log('Get successful:', response);
        this.visibilities = response.visibilityResponse;

      },
      error: (error) => {
        console.error('Error fetching Visibility:', error);
      },
      complete: () => {}
    });
}

getAllEventTimeOnPageLoad(){
  this.eventService.getAllEventTime().subscribe({
    next: (response) => {
      // Handle successful response here
      console.log('Get successful:', response);
      this.eventTimes = response.eventTimeResponse;

    },
    error: (error) => {
      console.error('Error fetching startTimes:', error);
    },
    complete: () => {}
  });
}


  async getGeocodingData(address:string){
      try {
        const response = await this.eventService.getGeocodingForAdddress(address).toPromise();
        console.log('Get Map Data successful:', response);
    
        if (response.status !== "OK") {
          this.toastr.error("Invalid Address: Cannot get coordinates", '', {
            closeButton: true,
          });
        } else {
          this.eventMaplatitude = response.results[0].geometry.location.lat;
          this.eventMaplongitude = response.results[0].geometry.location.lng;
          this.eventMapGeocode = response.results[0];
        }
      } catch (error) {
        console.error('Error fetching Map Details:', error);
      }
  }


  validateTimes(control: FormGroup): { [key: string]: any } | null {
    const startTime = control.get('eventStartTime')?.value;
    const endTime = control.get('eventEndTime')?.value;
  
    if (startTime && endTime && startTime >= endTime) {
      return { invalidTimes: true };  // Custom error key for targeted display
    }
  
    return null;
  }
  
  resetTheForm(){
    this.createEventForm.reset();
    this.addressControl.setValue("");
    this.cityControl.setValue("");
    this.stateControl.setValue("");
    this.zipControl.setValue("");

    const eventDateWithPicker: HTMLInputElement | null = document.getElementById("eventDateWithPicker") as HTMLInputElement;
    eventDateWithPicker.value="";
  }

  

  async onSubmit(){
  
    if (this.createEventForm.invalid) {
      // Form is valid, perform signup logic
      console.log("error validation");
      if(this.createEventForm.hasError('invalidTimes')){
        // alert("error dey");
      }

      Object.keys(this.createEventForm.controls).forEach(controlName => {
        const control = this.createEventForm.get(controlName);
        if (control?.errors) {
          console.log(`Validation errors for ${controlName}:`, control.errors);
        }
      });
      return
    } 

    // Set loading to true
    this.loading = true;

    // Access form values here
    const eventName = this.createEventForm.get('eventName')?.value;
    const eventAbout = this.createEventForm.get('eventAbout')?.value;
    const eventDescription = this.createEventForm.get('eventDescription')?.value;
    const eventLocationName = this.createEventForm.get('eventLocationName')?.value;

    const eventAddress = this.addressControl.value;
    const eventCity = this.cityControl.value;
    const eventState = this.stateControl.value;
    const eventZipCode = this.zipControl.value;

    const eventCategory = this.createEventForm.get('eventCategory')?.value;
    const eventVisibility = this.createEventForm.get('eventVisibility')?.value;
    // const eventDateWithPicker = this.createEventForm.get('eventDateWithPicker')?.value;
    const eventDateWithPicker: HTMLInputElement | null = document.getElementById("eventDateWithPicker") as HTMLInputElement;
    const eventStartTime = this.createEventForm.get('eventStartTime')?.value;
    const eventEndTime = this.createEventForm.get('eventEndTime')?.value;
    const eventEmail = this.createEventForm.get('eventEmail')?.value;
    const eventContactName = this.createEventForm.get('eventContactName')?.value;
    const eventContactPhone = this.createEventForm.get('eventContactPhone')?.value;
    const additionalInfo = this.createEventForm.get('additionalInfo')?.value;

    const fullAddress = `${eventAddress}, ${eventCity}, ${eventState}, ${eventZipCode}`;
    
     //get Geocoding details
     // Call getGeocodingData and wait for it to complete
     await this.getGeocodingData(fullAddress);
    
    const eventData = {
     eventName, eventAbout, eventDescription, eventLocationName, eventAddress, eventCity, eventState, eventZipCode,
     eventCategory, eventVisibility, eventDateWithPicker : eventDateWithPicker.value, eventStartTime, eventEndTime, eventEmail, eventContactName,
     eventContactPhone, additionalInfo, lat: this.eventMaplatitude, Lng: this.eventMaplongitude, geocode: "ola", admin_id: this.loggedInUserData.user_id
   }

   console.log("Event data passed to backend", eventData);


  this.eventService.createEvent(eventData).subscribe({
    next: (response) => {
      // Handle successful response here
      console.log('Post event Data successful:', response);
      this.toastr.success(`${response.message}`, '', {
        closeButton: true,
      });
      this.resetTheForm();

    },
    error: (error) => {
      console.error('Error fetching categories:', error);
      this.loading = false; 
      this.toastr.error('Unable to create event', '', {
        closeButton: true,
      });
    },
    complete: () => {
      this.loading = false; 
    }
  });
 
 
  }

  

}









// console.log('eventName:', eventName);
//     console.log('eventAbout:', eventAbout);
//     console.log('eventDescription:', eventDescription);
//     console.log('eventLocationName:', eventLocationName);
//     console.log('eventAddress:', eventAddress);
//     console.log('additionalInfo:', additionalInfo);
//     console.log('eventCity:', eventCity);
//     console.log('eventState:', eventState);
//     console.log('eventZipCode:', eventZipCode);
//     console.log('eventCategory:', eventCategory);
//     console.log('eventVisibility:', eventVisibility);
//     console.log('eventDateWithPicker:', eventDateWithPicker.value);
//     console.log('eventStartTime:', eventStartTime);
//     console.log('eventEndTime:', eventEndTime);
//     console.log('eventEmail:', eventEmail);
//     console.log('eventContactName:', eventContactName);
//     console.log('eventContactPhone:', eventContactPhone);