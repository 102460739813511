import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../states/app.state';
import { EventService } from '../../services/event-service/event.service';
  
import { GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-event-page',
  standalone: true,
  imports: [SharedModule, CommonModule, RouterOutlet, GoogleMapsModule],
  templateUrl: './event-page.component.html',
  styleUrl: './event-page.component.css'
})
export class EventPageComponent {

display: any;
location_name: string = "";
id: string = "";
event: any = [];
setToLoadingWhileGetData = false;
center!: google.maps.LatLngLiteral;



constructor(
  private eventService: EventService,
  private router: Router,
  private store: Store<AppState>,
  private route: ActivatedRoute
){}

ngOnInit(): void {
  this.route.params.subscribe(params => {
    this.id = params['id'];
    // Use the ID to fetch and display the item details
    console.log("Ola the id is", this.id);
  });

  this.getEventOnPageLoad(this.id);
}

async getEventOnPageLoad(eventId: string){
  this.setToLoadingWhileGetData = true;
  this.eventService.getEventById(eventId).subscribe({
    next: (response) => {
      // Handle successful response here
      console.log('Get successful single event:', response);
      this.event = response.event;

      this.center = {
        lat: parseFloat(this.event[0].latitude),
        lng: parseFloat(this.event[0].longitude)
      };

    },
    error: (error) => {
      console.error('Error fetching single event:', error);
    },
    complete: () => {
      this.setToLoadingWhileGetData = false;
    }
  });
}

// center: google.maps.LatLngLiteral = {
//   lat: 28.5968574,
//   lng: -81.20326940000001
// };

zoom = 17;

icon: google.maps.Icon = {
  // url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
  // url: 'https://cdn-icons-png.flaticon.com/128/149/149059.png', 
  // url: 'https://cdn-icons-png.flaticon.com/128/2776/2776067.png',
  url: 'https://cdn-icons-png.flaticon.com/128/1865/1865269.png',
  scaledSize: new google.maps.Size(30, 30) // Define scaledSize correctly
};


  moveMap(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.center = (event.latLng.toJSON());
  }
  
  move(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  

}
