<!-- BEGIN -->

<div id="myModal" class="modal animate__animated animate__fadeIn">
  <!-- hello -->

  <div class="main auth-signin">
    <div class="modal-menubar">
        <span class="close" title="close" (click)="closeModal()">&times;</span>
    </div>
    <p class="sign">Sign in</p>
    <form class="form1" [formGroup]="myLoginForm" (ngSubmit)="onSubmit()">
        <div class="input-container">
            <span class="input-icon">
                <span class="input-error" *ngIf="myLoginForm.get('email')!.hasError('required')">Email is required</span>
                <span class="input-error" *ngIf="myLoginForm.get('email')!.hasError('email')">Please enter a valid email address.</span>
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </span>
            <input class="un" type="email" formControlName="email" placeholder="Email" />
        </div>
        <div class="input-container">
            <span class="input-icon">
                <span class="input-error" *ngIf="myLoginForm.get('password')!.hasError('required')">Password is required</span>
                <ng-container *ngIf="loginSuccess; else showOpenPassword">
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </ng-container>
                <ng-template #showOpenPassword>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </ng-template>
            </span>
            <input class="pass" type="password" formControlName="password"  placeholder="Password" [type]="isPasswordVisible ? 'text' : 'password'" />
              <span class="eye-password" (click)="togglePasswordVisibility()">
                
                <ng-container *ngIf="isPasswordVisible; else showEyeSlash">
                    <i class="fa fa-eye" aria-hidden="true" title="Hide Password"></i>
                </ng-container>
                <ng-template #showEyeSlash>
                  <i class="fa fa-eye-slash" aria-hidden="true" title="Show Password"></i>
                </ng-template>
              </span>
        </div>
        <button type="submit" class="submit" [disabled]="loading">
          <span *ngIf="!loading">Sign in</span>
          <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin" style="font-size: 24px;"></i></span>
        </button>
    </form>
    <!-- <p class="signup" (click)="openSignupModal()"><a href="javascript:void(0);">Signup?</a></p>  -->
    <p class="signup"><a href="javascript:void(0);" routerLink="/signup">Signup?</a></p> 
    <p class="forgot"><a href="#">Forgot Password?</a></p>     
    
                <button class="ml-2 bg-blue-500 hover:bg-blue-700 text-white rounded mr-2 px-1" (click)="getTokens()">Get Token</button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white rounded mr-2 px-1"  (click)="logOut()">Logout</button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white rounded mr-2 px-1" (click)="checkExpired()">Is expired</button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white rounded mr-2 px-1" (click)="checkTokenData()">Check Data</button>

  </div>
</div>

<!-- END -->


<!-- OLD BEGIN -->

  <!-- <div class="bg-gray-800 flex items-center justify-center ">
    <div class="text-center mt-10 mb-10">
      <div class="mb-4">
        <h1 class="text-2xl font-bold text-gray-100">Counter App</h1>
      </div>
      <div id="counter" class="text-4xl font-bold text-blue-500 mb-4">
       {{count$ | async}}
      </div>
      <div class="flex justify-center space-x-4">
        <button (click)="increment()" id="increment"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Increment</button>
        <button (click)="decrement()" id="decrement"
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Decrement</button>
        <button (click)="reset()" id="reset"
          class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">Reset</button>
      </div>
    </div>
  </div> -->

<!-- END -->
