import { Component, ElementRef, OnInit  } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../../states/app.state';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { EventService } from '../../services/event-service/event.service';
import { ToastrService } from 'ngx-toastr';
import { addLoginUserToState } from '../../states/user/user.actions';
import { isJwtTokenExpired } from '../../utils/auth';
import { selectUser } from '../../states/user/user.selector';
import { UniversityService } from '../../services/university-service/university.service';

@Component({
  selector: 'app-university-page',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './university-page.component.html',
  styleUrl: './university-page.component.css'
})
export class UniversityPageComponent implements OnInit{

  loading = false; // Add loading flag
  autocomplete: any;

  universityForm: FormGroup;
  createEventSuccess = false; // Add loading flag
  eventMaplatitude: any = "inital value lat";
  eventMaplongitude: any = "initial value lng";
  eventMapGeocode: any
  loggedInUserData: any; 
  selectedCoverPhotoFile: File | null = null;
  selectedPhotoFile: File | null = null;

  selectedImageUrl: string | ArrayBuffer | null = 'https://cdn-icons-png.flaticon.com/512/3237/3237472.png'; // Initial image URL
  selectedCoverImageUrl: string | ArrayBuffer | null = 'https://cdn-icons-png.flaticon.com/512/5175/5175601.png'; // Initial image URL

  

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private universityService: UniversityService,
    private elementRef:ElementRef,
    private toastr: ToastrService
 ){ 

   this.universityForm = this.formBuilder.group({
     universityName: ['', [Validators.required]],
     universityAbout: ['', [Validators.required]],
     universityNumber: ['', [Validators.required, this.validateUniversityNumber]],
     universityEmail: ['', [Validators.required, Validators.email]],
     additionalInfo: [''],
   });

 }

 validateUniversityNumber(control:any) {
  const numberPattern = /^[0-9]+$/;
  if (control.value && !numberPattern.test(control.value)) {
    return { error: "Only number Allowed" };
  }
  return null;
}

 ngOnInit(): void {
  /******************Logged user code************************* */

  if(isJwtTokenExpired()){ //if true
    this.router.navigate(['/login']);
    return
  }

   // Fetch user data from storage
   const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

   if (storedUserData) {
     this.store.dispatch(addLoginUserToState({ user: storedUserData }));
   }

   this.store.select(selectUser).subscribe(userState => {
    this.loggedInUserData = userState;
    console.log("loggedInUserData", this.loggedInUserData); 
   });

  /******************end of user code************************* */

   this.initAutocomplete();
   
}

addressControl = new FormControl();
cityControl = new FormControl();
stateControl = new FormControl();
zipControl = new FormControl();



initAutocomplete() {
  const autocompleteInput = document.getElementById('autocompleteInput') as HTMLInputElement;
  this.autocomplete = new google.maps.places.Autocomplete(autocompleteInput);
  this.autocomplete.addListener('place_changed', () => {
    const place = this.autocomplete.getPlace();
    // this.addressControl.setValue(place.formatted_address);

    // Extract city, state/province, and ZIP/postal code from address components
    const addressComponents = place.address_components;

    // Extract street address
    const streetAddress = place?.formatted_address.split(',')[0];
    // Set street address to form control
    this.addressControl.setValue(streetAddress);

    addressComponents.forEach((component: { types: string | string[]; long_name: any; }) => {
      if (component.types.includes('locality')) {
        this.cityControl.setValue(component.long_name);
      } else if (component.types.includes('administrative_area_level_1')) {
        this.stateControl.setValue(component.long_name);
      } else if (component.types.includes('postal_code')) {
        this.zipControl.setValue(component.long_name);
      }
    });
  });
}

  async getGeocodingData(address:string){
      try {
        const response = await this.universityService.getGeocodingForAdddress(address).toPromise();
        console.log('Get Map Data successful:', response);
    
        if (response.status !== "OK") {
          this.toastr.error("Invalid Address: Cannot get coordinates", '', {
            closeButton: true,
          });
        } else {
          this.eventMaplatitude = response.results[0].geometry.location.lat;
          this.eventMaplongitude = response.results[0].geometry.location.lng;
          this.eventMapGeocode = response.results[0];
        }
      } catch (error) {
        console.error('Error fetching Map Details:', error);
      }
  }




  onPhotoFileSelected(event: any) {
    this.selectedPhotoFile = event.target.files[0] as File;

    const file: File = event.target.files[0];
    const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImageUrl = e.target?.result as string;
      };
      reader.readAsDataURL(file);
  }

  onCoverPhotoFileSelected(event: any) {
    this.selectedCoverPhotoFile = event.target.files[0] as File;

    const file: File = event.target.files[0];
    const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedCoverImageUrl = e.target?.result as string;
      };
      reader.readAsDataURL(file);
  }



  resetTheForm(){
    this.universityForm.reset();
    this.addressControl.setValue("");
    this.cityControl.setValue("");
    this.stateControl.setValue("");
    this.zipControl.setValue("");
  }

  

async onSubmit(){

  const universityName = this.universityForm.get('universityName')?.value;
  const universityAbout = this.universityForm.get('universityAbout')?.value;
  const universityEmail = this.universityForm.get('universityEmail')?.value;

  const additionalInfo = this.universityForm.get('additionalInfo')?.value;
  const universityNumber = this.universityForm.get('universityNumber')?.value;

  const universityAddress = this.addressControl.value;
  const universityCity = this.cityControl.value;
  const universityState = this.stateControl.value;
  const universityZipCode = this.zipControl.value;

if(universityName === ""){
    this.toastr.error('University name is required', '', { closeButton: true, });
    return
}

if(universityAbout === ""){
  this.toastr.error('About is required', '', { closeButton: true,  });
  return
}

if(universityEmail === ""){
  this.toastr.error('Email is required', '', {  closeButton: true, });
  return
}

if(this.universityForm.get('universityEmail')?.errors){
  this.toastr.error('Invalid Email Address', '', { closeButton: true, });
  return
}


if(universityNumber === ""){
  this.toastr.error('Number is required', '', { closeButton: true, });
  return
}

if(universityAddress === ""){
  this.toastr.error('Address is required', '', { closeButton: true, });
  return
}

if(universityCity === ""){
  this.toastr.error('City is required', '', { closeButton: true, });
  return
}

if(universityState === ""){
  this.toastr.error('State is required', '', { closeButton: true,  });
  return
}

if(universityZipCode === ""){
  this.toastr.error('Zip Code is required', '', { closeButton: true, });
  return
}




if (this.universityForm.invalid) {
    Object.keys(this.universityForm.controls).forEach(controlName => {
      const control = this.universityForm.get(controlName);
      if (control?.errors) {
        this.toastr.error(`${control.errors}`, '', {
          closeButton: true,
        });
        console.log(`Validation errors for ${controlName}:`, control.errors);
      }
    });

    return
}


  if (!this.selectedPhotoFile) {
    this.toastr.error('Upload a Photo', '', {
      closeButton: true,
    });
    return;
  }

  if (!this.selectedCoverPhotoFile) {
    this.toastr.error('Upload a cover Image', '', {
      closeButton: true,
    });
    return;
  }


 

  // Set loading to true
  this.loading = true;

  const fullAddress = `${universityAddress}, ${universityCity}, ${universityState}, ${universityZipCode}`;

   // Call getGeocodingData and wait for it to complete (get Geocoding details)
   await this.getGeocodingData(fullAddress);
  
  const universityData: any = {
    universityName, universityAbout, universityEmail, universityNumber, universityAddress, universityCity, universityState, universityZipCode,
    additionalInfo, lat: this.eventMaplatitude, Lng: this.eventMaplongitude, geocode: "ola", admin_id: this.loggedInUserData.user_id
 }

 console.log("Event data passed to backend", universityData);

 const formData = new FormData();

  // Append file to FormData
  formData.append('file', this.selectedCoverPhotoFile);
  formData.append('file2', this.selectedPhotoFile);

  
  // Append other data to FormData
  Object.keys(universityData).forEach((key) => {
    formData.append(key, universityData[key]);
  });


this.universityService.createUniversity(formData).subscribe({
  next: (response) => {
    // Handle successful response here
    console.log('Post University Data successful:', response);
    this.toastr.success(`${response.message}`, '', {
      closeButton: true,
    });
    this.resetTheForm();

  },
  error: (error) => {
    console.error('Error posting University:', error);
    this.toastr.error('Unable to create university', '', {
      closeButton: true,
    });
    this.loading = false; 
  },
  complete: () => {
    this.loading = false; 
  }
});

  
}



}
