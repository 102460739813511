import { Component } from '@angular/core';
import { addLoginUserToState, removeLoginUserFromSTate } from '../../states/user/user.actions';
import { isJwtTokenExpired, logout } from '../../utils/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../states/app.state';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-dash-board-page',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dash-board-page.component.html',
  styleUrl: './dash-board-page.component.css'
})
export class DashBoardPageComponent {
  
  constructor(
    private router: Router,
    private store: Store<AppState>
  ){}

  ngOnInit(): void {
    if(isJwtTokenExpired()){ //if true
      this.router.navigate(['/login']);
      return
    }

     // Fetch user data from storage
     const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

     if (storedUserData) {
       this.store.dispatch(addLoginUserToState({ user: storedUserData }));
     }

  }


  logOutAUser(): void{
    this.store.dispatch(removeLoginUserFromSTate());
    logout();
    this.router.navigate(['/login']);
  }


}
