<!-- <p>university-page works!</p> -->

<!-- <p>profile-page works!</p>


<div *ngIf="user$ | async as user">
    <h2>Welcome, {{ user.firstName }} {{ user.lastName }}!</h2>
    <p>Email: {{ user.email }}</p>
    <p>User Level: {{ user.user_level }}</p>
    <p>created at: {{ user.created_at }}</p>
  </div> -->


  <div class="create-event-body container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <form [formGroup]="createEventForm" (ngSubmit)="onSubmit()">
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Create an Event</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-4">
                    <label for="event-name" class="block text-sm font-medium leading-6 text-gray-900">Event Name</label>
                    <div class="mt-2">
                      <input formControlName="eventName" id="event-name" name="event-name" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>
      
              <div class="col-span-full">
                <label for="event-about" class="block text-sm font-medium leading-6 text-gray-900">About</label>
                <div class="mt-2">
                  <textarea formControlName="eventAbout" id="event-about" name="event-about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <p class="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about the event.</p>
              </div>

              <div class="col-span-full">
                <label for="event-description" class="block text-sm font-medium leading-6 text-gray-900">Event Description</label>
                <div class="mt-2">
                  <textarea formControlName="eventDescription" id="event-description" name="event-description" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <p class="mt-3 text-sm leading-6 text-gray-600">Write a detail description about the event.</p>
              </div>
      
            
            </div>
          </div>
      
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Event Address</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      
              <div class="sm:col-span-4">
                <label for="event-location-Name" class="block text-sm font-medium leading-6 text-gray-900">Location Name</label>
                <div class="mt-2">
                  <input formControlName="eventLocationName" id="event-location-Name" name="event-location-Name" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
      
          
              <!-- <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Street address</label>
                <div class="mt-2">
                  <input formControlName="eventAddress" type="text" name="street-address" id="street-address" autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div> -->

              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Street address</label>
                <div class="mt-2">
                  <input [formControl]="addressControl" type="text" id="autocompleteInput" placeholder="Enter a location" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              
              <div class="sm:col-span-2 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City</label>
                <div class="mt-2">
                  <input [formControl]="cityControl" type="text" name="city" id="city" autocomplete="city" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              
              <div class="sm:col-span-2">
                <label for="state" class="block text-sm font-medium leading-6 text-gray-900">State / Province</label>
                <div class="mt-2">
                  <input [formControl]="stateControl" type="text" name="state" id="state" autocomplete="state" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              
              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code</label>
                <div class="mt-2">
                  <input [formControl]="zipControl" placeholder="" type="text" name="postal-code" id="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="col-span-full">
                <label for="additionalInfo" class="block text-sm font-medium leading-6 text-gray-900">Additional Information</label>
                <div class="mt-2">
                  <input formControlName="additionalInfo" type="text" id="additionalInfo" placeholder="(i.e Apt Number)" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

            </div>
          </div>



          <div class="border-b border-gray-900/10 pb-12">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">Event Information</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
            
                    <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-3 sm:col-start-1">
                            <label for="category" class="block text-sm font-medium leading-6 text-gray-900">Category</label>
                            <div class="mt-2">
                                <select formControlName="eventCategory" id="category" name="category" class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <option value="">Select a category</option>
                                    <option *ngFor="let category of categories" [value]="category.category_id">{{ category.name }}</option>
                                  </select>
                            </div>
                        </div>
                    
                        <div class="sm:col-span-3">
                            <label for="visibility" class="block text-sm font-medium leading-6 text-gray-900">Visibility</label>
                            <div class="mt-2">
                              <select #mySelectVisibility formControlName="eventVisibility" id="visibility" name="visibility" class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" (change)="onVisibilityChange(mySelectVisibility.value)">
                                <option value="">Select visibility</option>
                                <option *ngFor="let visibility of visibilities" [value]="visibility.visibility_id">{{ visibility.name }}</option>
                              </select>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="showRsoOptionSelect">
                      <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-3 sm:col-start-1">
                            <label for="category" class="block text-sm font-medium leading-6 text-gray-900">Members in this RSO Group can only see this event</label>
                            <div class="mt-2">
                                <select formControlName="eventCategory" id="category" name="category" class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <option value="">Select a RSO Group</option>
                                    <option *ngFor="let category of categories" [value]="category.category_id">{{ category.name }}</option>
                                  </select>
                            </div>
                        </div>
                      </div>
                    </ng-container>

                   

                    <div class="relative max-w-sm mt-10">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                        </svg>
                        </div>
                        <input id="eventDateWithPicker" datepicker datepicker-autohide datepicker-buttons datepicker-autoselect-today type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer" placeholder="Select date">
                    </div>
        
                    <div class="flex mt-10">
                        <div class="mr-4">
                            <label for="start-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time</label>
                            <select formControlName="eventStartTime" id="start-time" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">Choose start time</option>
                                <option *ngFor="let eventTime of eventTimes" [value]="eventTime.event_time">{{ eventTime.event_time }}</option>
                            </select>
                        </div>
                    
                        <div>
                            <label for="end-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time</label>
                            <select formControlName="eventEndTime" id="end-time" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">Choose end time</option>
                                <option *ngFor="let eventTime of eventTimes" [value]="eventTime.event_time">{{ eventTime.event_time }}</option>
                            </select>
                        </div>
                    </div>
          </div>


          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Contact Information</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      
              <div class="sm:col-span-4">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Contact Email</label>
                <div class="mt-2">
                  <input formControlName="eventEmail" id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
    
            </div>

            <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3 sm:col-start-1">
                    <label for="contact-name" class="block text-sm font-medium leading-6 text-gray-900">Contact Name</label>
                    <div class="mt-2">
                      <input formControlName="eventContactName" type="text" name="contact-name" id="contact-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
            
                <div class="sm:col-span-3">
                    <label for="contact-phone" class="block text-sm font-medium leading-6 text-gray-900">Contact Phone</label>
                    <div class="mt-2">
                      <input formControlName="eventContactPhone" type="text" name="contact-phone" id="contact-phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
            </div>

          </div>

            
        </div>

  
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <!-- <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> -->
          <button type="submit" [disabled]="loading" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <span *ngIf="!loading">Create Event</span>
            <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin" style="font-size: 24px;"></i></span>
          </button>
        </div>

      </form>
  </div>




  <!-- Option time
  <option value="12:00AM">12:00AM</option>
  <option value="12:30AM">12:30AM</option>
  <option value="1:00AM">1:00AM</option>
  <option value="1:30AM">1:30AM</option>
  <option value="2:00AM">2:00AM</option>
  <option value="2:30AM">2:30AM</option>
  <option value="3:00AM">3:00AM</option>
  <option value="3:30AM">3:30AM</option>
  <option value="4:00AM">4:00AM</option>
  <option value="4:30AM">4:30AM</option>
  <option value="5:00AM">5:00AM</option>
  <option value="5:30AM">5:30AM</option>
  <option value="6:00AM">6:00AM</option>
  <option value="6:30AM">6:30AM</option>
  <option value="7:00AM">7:00AM</option>
  <option value="7:30AM">7:30AM</option>
  <option value="8:00AM">8:00AM</option>
  <option value="8:30AM">8:30AM</option>
  <option value="9:00AM">9:00AM</option>
  <option value="9:30AM">9:30AM</option>
  <option value="10:00AM">10:00AM</option>
  <option value="10:30AM">10:30AM</option>
  <option value="11:00AM">11:00AM</option>
  <option value="11:30AM">11:30AM</option>
  <option value="12:00PM">12:00PM</option>
  <option value="12:30PM">12:30PM</option>
  <option value="1:00PM">1:00PM</option>
  <option value="1:30PM">1:30PM</option>
  <option value="2:00PM">2:00PM</option>
  <option value="2:30PM">2:30PM</option>
  <option value="3:00PM">3:00PM</option>
  <option value="3:30PM">3:30PM</option>
  <option value="4:00PM">4:00PM</option>
  <option value="4:30PM">4:30PM</option>
  <option value="5:00PM">5:00PM</option>
  <option value="5:30PM">5:30PM</option>
  <option value="6:00PM">6:00PM</option>
  <option value="6:30PM">6:30PM</option>
  <option value="7:00PM">7:00PM</option>
  <option value="7:30PM">7:30PM</option>
  <option value="8:00PM">8:00PM</option>
  <option value="8:30PM">8:30PM</option>
  <option value="9:00PM">9:00PM</option>
  <option value="9:30PM">9:30PM</option>
  <option value="10:00PM">10:00PM</option>
  <option value="10:30PM">10:30PM</option>
  <option value="11:00PM">11:00PM</option>
  <option value="11:30PM">11:30PM</option> -->
  