import { Component, OnInit } from '@angular/core';
import {saveJwtToken, logout, isJwtTokenExpired, getJwtToken, getJwtTokenData } from "../../utils/auth";
import { Router } from '@angular/router';
import { selectUser } from '../../states/user/user.selector';

import { AppState } from '../../states/app.state';
import { ProfileService } from '../../services/profile-service/profile.service';
import { Store } from '@ngrx/store';
import { addLoginUserToState } from '../../states/user/user.actions';
import { ToastrService } from 'ngx-toastr';

import { SharedModule } from '../../shared.module';
import { AsyncPipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';


@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [
    SharedModule,
    AsyncPipe
  ],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.css'
})

export class ProfilePageComponent implements OnInit{

  user$ = this.store.select(selectUser);
  userId: string = "";
  userDbDetails: any = [];
  loggedInUserData: any; 
  loading = false;
  profileForm: FormGroup; 

  selectedImageUrl: string | ArrayBuffer | null = 'https://cdn-icons-png.flaticon.com/512/3237/3237472.png';
  selectedPhotoFile: File | null = null;
  
  constructor(
     private router: Router,
     private store: Store<AppState>,
     private profileService: ProfileService,
     private formBuilder: FormBuilder,
     private toastr: ToastrService
  ){

    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      about: ['', [Validators.required]],
      email: ['', [Validators.required]],
      userRole: ['', [Validators.required]],
      university: ['']
    });
   }

  ngOnInit(): void {
    if(isJwtTokenExpired()){ //if true
      this.router.navigate(['/login']);
      return
    }

     // Fetch user data from storage
     const storedUserData = JSON.parse(localStorage.getItem('currentUser') as string);

     if (storedUserData) {
       this.store.dispatch(addLoginUserToState({ user: storedUserData }));
     }

     this.store.select(selectUser).subscribe(userState => {
      this.loggedInUserData = userState;
      // console.log("loggedInUserData", this.loggedInUserData); 
      // console.log("loggedInUserId", this.loggedInUserData.user_id); 
     });

    this.getUserProfileOnPageLoad(this.loggedInUserData.user_id);

    //disbale form 
    this.profileForm.controls['email'].disable();
    this.profileForm.controls['userRole'].disable();
    this.profileForm.controls['university'].disable();
  }


  async getUserProfileOnPageLoad(userId: string){
    this.profileService.getProfileById(userId).subscribe({
      next: (response) => {
        // Handle successful response here
        console.log('Get successful user details on profile Pag:', response);
        this.userDbDetails = response.user;
        
        this.profileForm.setValue({
          firstName: this.userDbDetails[0].user_firstName,
          lastName: this.userDbDetails[0].user_lastName,
          about: this.userDbDetails[0].about,
          email: this.userDbDetails[0].user_email,
          userRole: this.userDbDetails[0].user_level,
          university: ""
        });
  
  
      },
      error: (error) => {
        console.error('Error fetching user details on profile Page:', error);
      },
      complete: () => {
        // this.setToLoadingWhileGetData = false;
      }
    });
  }

  onPhotoFileSelected(event: any) {
    this.selectedPhotoFile = event.target.files[0] as File;

    const file: File = event.target.files[0];
    const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImageUrl = e.target?.result as string;
      };
      reader.readAsDataURL(file);
  }

  onSubmit(){
    this.loading = true; 

    // if (!this.selectedPhotoFile) {
    //   this.toastr.error('Upload a Photo', '', {
    //     closeButton: true,
    //   });
    //   return;
    // }

    // this.profileService.createEvent(eventData).subscribe({
    //   next: (response) => {
    //     // Handle successful response here
    //     console.log('Post event Data successful:', response);
    //     this.toastr.success(`${response.message}`, '', {
    //       closeButton: true,
    //     });
    //     this.resetTheForm();
  
    //   },
    //   error: (error) => {
    //     console.error('Error fetching categories:', error);
    //     this.loading = false; 
    //     this.toastr.error('Unable to create event', '', {
    //       closeButton: true,
    //     });
    //   },
    //   complete: () => {
    //     this.loading = false; 
    //   }
    // });
   
  }

}

