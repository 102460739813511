import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private baseUrl = environment.apiUrl; 

   private googleMapApiKey = environment.googleMapApiKey; 

  constructor(private http: HttpClient) { }

  getGeocodingForAdddress(address: string): Observable<any> {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.googleMapApiKey}`);
  }

  getAllEventCategory(): Observable<any> {
    return this.http.get<{name: string}[]>(`${this.baseUrl}/event/category`);
  }

  getAllVisibility(): Observable<any> {
    return this.http.get<{name: string}[]>(`${this.baseUrl}/event/visibility`);
  }

  getAllEventTime(): Observable<any> {
    return this.http.get<{name: string}[]>(`${this.baseUrl}/event/event-time`);
  }

  getAllEvents(): Observable<any> {
    return this.http.get<{name: string}[]>(`${this.baseUrl}/events`);
  }

  getEventById(eventId: string): Observable<any> {
    return this.http.get<{name: string}[]>(`${this.baseUrl}/event/${eventId}`);
  }


  createEvent(eventData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/event/create`, eventData);
  }

}
