<!-- <p>university-page works!</p> -->

<!-- <p>profile-page works!</p>


<div *ngIf="user$ | async as user">
    <h2>Welcome, {{ user.firstName }} {{ user.lastName }}!</h2>
    <p>Email: {{ user.email }}</p>
    <p>User Level: {{ user.user_level }}</p>
    <p>created at: {{ user.created_at }}</p>
  </div> -->


  <div class="rso-body container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <form [formGroup]="rsoForm" (ngSubmit)="onSubmit()">
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Create a RSO Organization</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-full">
                    <label for="rso-name" class="block text-sm font-medium leading-6 text-gray-900">RSO Name</label>
                    <div class="mt-2">
                      <input formControlName="rsoName" id="rso-name" name="rso-name" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
      
                <div class="col-span-full">
                  <label for="about" class="block text-sm font-medium leading-6 text-gray-900">About</label>
                  <div class="mt-2">
                    <textarea formControlName="about" id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                  </div>
                  <p class="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about the RSO.</p>
                </div>
              
      
              <div class="col-span-full">
                <label for="photo" class="block text-sm font-medium leading-6 text-gray-900">University Logo</label>
                <div class="mt-2 flex items-center gap-x-3">
                  <img class="h-12 w-12 text-gray-300" [src]="selectedImageUrl" />
                   <label for="photo-upload" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                     <span class="cursor-pointer">Upload a Photo</span>
                     <input (change)="onPhotoFileSelected($event)" id="photo-upload" name="photo-upload" type="file" class="sr-only">
                   </label>
                </div>
              </div>
      
              <div class="col-span-full">
                <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Cover photo</label>
                <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div class="text-center">
                    <img class="mx-auto h-12 w-12 text-gray-300" [src]="selectedCoverImageUrl" />
                    <div class="mt-4 flex text-sm leading-6 text-gray-600">
                      <label for="coverPhotofile-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                        <span>Upload a Image</span>
                        <input (change)="onCoverPhotoFileSelected($event)" id="coverPhotofile-upload" name="coverPhotofile-upload" type="file" class="sr-only">
                      </label>
                      <!-- <p class="pl-1">or drag and drop</p> -->
                    </div>
                    <!-- <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p> -->
                    <!-- <p class="text-xs leading-5 text-gray-600">To use for your University cover Photo</p> -->
                  </div>
                </div>

                  <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-3 sm:col-start-1">
                        <label for="contact-email" class="block text-sm font-medium leading-6 text-gray-900">Contact Email</label>
                        <div class="mt-2">
                          <input formControlName="ContactEmail" type="text" name="contact-email" id="contact-email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                    </div>
                
                    <div class="sm:col-span-3">
                        <label for="contact-phone" class="block text-sm font-medium leading-6 text-gray-900">Contact Phone</label>
                        <div class="mt-2">
                          <input formControlName="ContactPhone" type="text" name="contact-phone" id="contact-phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Members</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Add at least 4 members to the organization</p>
            <button type="button" (click)="addDropdown()" class="mt-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add a Member</button>

            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div *ngFor="let dropdown of dropdowns; let i = index" class="sm:col-span-4">
                <label for="addMember{{i}}" class="block text-sm font-medium leading-6 text-gray-900">Pick a Student</label>
                <div class="mt-2">
                  <span class="addMemberdelIcon"><i (click)="deleteDropdown(i)" class="fa fa-trash-o cursor-pointer" aria-hidden="true" title="deleted"></i></span>
                  <select formControlName="addMember{{i}}" id="addMember{{i}}" name="addMember{{i}}" class="capitalize rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" (change)="onDropdownChange($event, i)">
                    <option value="">Select a Member</option>
                    <option *ngFor="let student of students" [value]="student.user_id">{{ student.user_firstName }} {{ student.user_lastName }}</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
      
        </div>
      
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button type="submit" [disabled]="loading" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <span *ngIf="!loading">Create RSO</span>
            <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin" style="font-size: 24px;"></i></span>
          </button>
        </div>

    </form>
  </div>