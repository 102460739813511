import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-page',
  standalone: true,
  imports: [],
  templateUrl: './reset-password-page.component.html',
  styleUrl: './reset-password-page.component.css'
})
export class ResetPasswordPageComponent {

}
