<div id="myModal" class="modal animate__animated animate__fadeIn">
    <!-- hello -->
  
    <div class="main auth-signin">
      <div class="modal-menubar">
          <span class="close" title="close" (click)="closeModal()">&times;</span>
      </div>
      <p class="sign">Comments</p>
        <div class="comment-container">
            <div class="comment-body-container">
                <!-- Your comments will be dynamically added here -->
                <!-- Example comment -->
               
                    <div *ngFor="let comment of comments" class="comment">
                        <div class="whocomment">
                            <!-- 05/10/2023 14:23:12 -->
                            <ng-container *ngIf="isUserLoggedIn === true && comment.user_id == loggedInUserData.user_id">
                                <span class="showitisyou">You:</span> 
                            </ng-container>
                            <ng-container *ngIf="isUserLoggedIn === true || isUserLoggedIn === false">
                                {{comment.firstName }} {{comment.lastName }} 
                            </ng-container>
                            
                            {{this.formatDateTime(comment.updated_at)}}
                            <ng-container *ngIf="isUserLoggedIn === true && comment.user_id == loggedInUserData.user_id">
                                <span class="edit-icons-comment">
                                    <i (click)="deleteComment(comment)" title="Delete Comment" class="fa fa-trash-o mr-2 cursor-pointer" aria-hidden="true"></i>
                                    <i (click)="editComment(comment)" title="Edit Comment" class="fa fa-pencil-square-o cursor-pointer" aria-hidden="true"></i>
                                </span>
                            </ng-container>
                        </div>
                         <div class="commentitself">{{ comment.comment_text}}</div>
                         <ng-container *ngIf="comment.rating == '1'">
                            <span class="rate-visible">⭐</span>
                         </ng-container>
                         <ng-container *ngIf="comment.rating == '2'">
                            <span class="rate-visible">⭐⭐</span>
                         </ng-container>
                         <ng-container *ngIf="comment.rating == '3'">
                            <span class="rate-visible">⭐⭐⭐</span>
                         </ng-container>
                         <ng-container *ngIf="comment.rating == '4'">
                            <span class="rate-visible">⭐⭐⭐⭐</span>
                         </ng-container>
                         <ng-container *ngIf="comment.rating == '5'">
                            <span class="rate-visible">⭐⭐⭐⭐⭐</span>
                         </ng-container>
                         
                    </div>
                
                <!-- End of example comments -->
            </div>
  
        </div>

        <form class="form1" [formGroup]="myCommentForm" (ngSubmit)="onSubmit()">
            <div class="comment-section">
                <div class="sm:col-span-3">
                    <div class="mt-2">
                      <select #mySelectRating formControlName="rating" class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" (change)="onRatingChange(mySelectRating.value)">
                        <option value="">Select Rating</option>
                        <option value="1">⭐</option>
                        <option value="2">⭐⭐</option>
                        <option value="3">⭐⭐⭐</option>
                        <option value="4">⭐⭐⭐⭐</option>
                        <option value="5">⭐⭐⭐⭐⭐</option>                     
                      </select>
                    </div>
                </div>
                <div class="mt-2">
                    <textarea formControlName="comment" id="comment-input" placeholder="Write your comment here..."></textarea>
                </div>
                
            </div>
            <button type="submit" class="submit" [disabled]="loading">
                <span *ngIf="!loading">{{ isUpdateComment ? 'Update Comment' : 'Post Comment' }}</span>
                <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin" style="font-size: 24px;"></i></span>
            </button>
        </form>
    
    </div>
</div>